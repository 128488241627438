import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import { GoogleOAuthProvider } from '@react-oauth/google'
import Theme from '@/components/template/Theme'
import Layout from '@/components/layouts'
import mockServer from './mock'
import appConfig from '@/configs/app.config'
import './locales'
import { LoadingProvider } from './utils/context/LoadingContext'

const environment = process.env.NODE_ENV

if (environment !== 'production' && appConfig.enableMock) {
    mockServer({ environment })
}

function App() {
    const google_oauth_id = import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID

    let InnerContent = (
        <BrowserRouter>
            <Theme>
                <Layout />
            </Theme>
        </BrowserRouter>
    )

    if (google_oauth_id) {
        InnerContent = (
            <GoogleOAuthProvider clientId={google_oauth_id}>
                {InnerContent}
            </GoogleOAuthProvider>
        )
    }

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <LoadingProvider>{InnerContent}</LoadingProvider>
            </PersistGate>
        </Provider>
    )
}

export default App
