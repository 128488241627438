import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    timeLeft: 0,
    requestCount: 0,
    otpSentAt: null,
}

const otpTimerSlice = createSlice({
    name: 'smsTimer',
    initialState,
    reducers: {
        setTimeLeft: (state, action) => {
            state.timeLeft = action.payload
        },
        decrementTime: (state) => {
            if (state.timeLeft > 0) state.timeLeft -= 1
        },
        resetTimeLeft: (state) => {
            state.timeLeft = 0
        },
        incrementRequestCount: (state) => {
            state.requestCount += 1
        },
        resetRequestCount: (state) => {
            state.requestCount = 0
        },
        setOtpSentAt: (state, action) => {
            state.otpSentAt = action.payload
        },
    },
})

export const {
    setTimeLeft,
    decrementTime,
    resetTimeLeft,
    incrementRequestCount,
    resetRequestCount,
    setOtpSentAt,
} = otpTimerSlice.actions

export default otpTimerSlice.reducer
