import ApiService from './ApiService'
import type {
    SignInCredential,
    SignUpCredential,
    ForgotPassword,
    ResetPassword,
    SignInResponse,
    SignUpResponse,
    verifyMobile,
} from '@/@types/auth'

const baseUrl = import.meta.env.VITE_API_BASE_URL
const apiVersion = import.meta.env.VITE_API_VERSION

export async function apiSignIn(data: SignInCredential) {
    return ApiService.fetchData<SignInResponse>({
        url: `${baseUrl}/api/auth/login`,
        method: 'post',
        data,
    })
}

export async function apiSignUp(data: SignUpCredential) {
    return ApiService.fetchData<SignUpResponse>({
        url: `${baseUrl}/api/auth/register`,
        method: 'post',
        data,
    })
}

export async function apiSignOut() {
    return ApiService.fetchData({
        url: `${baseUrl}/api/auth/logout`,
        method: 'post',
    })
}

export async function apiForgotPassword(data: ForgotPassword) {
    return ApiService.fetchData({
        url: `${baseUrl}/api/auth/password/forget`,
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data: ResetPassword) {
    return ApiService.fetchData({
        url: `${baseUrl}/api/auth/password/reset`,
        method: 'post',
        data,
    })
}

export async function apiSendOtp({ force }) {
    let url = `${baseUrl}${apiVersion}/otp/sms/send`
    if (force) url += `?force=${force}`

    return ApiService.fetchData({
        url,
        method: 'post',
    })
}

export async function apiVerifyMobile(data: verifyMobile) {
    return ApiService.fetchData({
        url: `${baseUrl}${apiVersion}/otp/sms/verify?otp=${data.otp}`,
        method: 'put',
    })
}

export async function apiSendVerificationEmail() {
    return ApiService.fetchData({
        url: `${baseUrl}${apiVersion}/email/send`,
        method: 'get',
    })
}

export async function apiVerifyEmail(params) {
    const { expires, hash, id, signature } = params
    return ApiService.fetchData({
        url: `${baseUrl}${apiVersion}/email/verify?expires=${expires}&hash=${hash}&id=${id}&signature=${signature}`,
        method: 'get',
    })
}
