import React, { createContext, useState, useContext, ReactNode } from 'react';

type LoadingContextType = {
    loading: boolean;
    setLoading: (loading: boolean) => void;
  };
  
  // Create a context with a default value
  const LoadingContext = createContext<LoadingContextType>({
    loading: false,
    setLoading: () => {},
  });
  

  type LoadingProviderProps = {
    children: ReactNode;
  };


export const LoadingProvider = ({ children }: LoadingProviderProps) => {
    const [loading, setLoading] = useState(false);

    return (
        <LoadingContext.Provider value={{ loading, setLoading }}>
            {children}
        </LoadingContext.Provider>
    );
};

export const useLoading = () => {
    const context = useContext(LoadingContext);
    if (!context) {
        throw new Error('useLoading must be used within a LoadingProvider');
    }
    return context;
};