import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SLICE_BASE_NAME } from './constants'

type Country = {
    id: string
    name: string
    iso: string
    iso3: string
}

export type UserState = {
    name?: string
    email?: string
    country?: Country
    profiles?: 'seeker' | 'provider' | ''
    authority?: 'seeker' | 'provider' | ''
    mobile_verified: boolean
    email_verified: boolean
}

const initialState: UserState = {
    name: '',
    email: '',
    country: {
        id: '',
        name: '',
        iso: '',
        iso3: '',
    },
    profiles: '',
    authority: '',
    mobile_verified: false,
    email_verified: false,
}

const userSlice = createSlice({
    name: `${SLICE_BASE_NAME}/user`,
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<UserState>) {
            state.email = action.payload?.email
            state.name = action.payload?.name
            state.country = action.payload?.country
            state.profiles = action.payload?.profiles
            state.authority = action.payload?.authority
            state.mobile_verified = action.payload.mobile_verified
            state.email_verified = action.payload.email_verified
        },
        setMobileVerified(state, action: PayloadAction<boolean>) {
            state.mobile_verified = action.payload
        },
        setEmailVerified(state, action: PayloadAction<boolean>) {
            state.email_verified = action.payload
        },
    },
})

export const { setUser, setMobileVerified, setEmailVerified } =
    userSlice.actions
export default userSlice.reducer
