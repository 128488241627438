import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import i18n from '@/locales'
import BaseService from './BaseService'

const ApiService = {
    fetchData<Response = unknown, Request = Record<string, unknown>>(
        param: AxiosRequestConfig<Request>
    ) {
        const headers = {
            ...param.headers,
            'Cache-Control': 'no-cache',
            'Accept-Language': i18n.language || 'en',
        }

        const modifiedParam = {
            ...param,
            headers,
        }

        return new Promise<AxiosResponse<Response>>((resolve, reject) => {
            BaseService(modifiedParam)
                .then((response: AxiosResponse<Response>) => {
                    resolve(response)
                })
                .catch((errors: AxiosError) => {
                    reject(errors)
                })
        })
    },
}

export default ApiService
